import React from 'react'
import Navigation from '../../components/composed/Navigation/Navigation'
import './Home.scss'
import { Button } from '../../components/simple/Button/Button'
import { ReactComponent as DecoOne } from '../../assets/homeWelcomeDeco1.svg'
import { ReactComponent as DecoTwo } from '../../assets/homeWelcomeDeco2.svg'
import SectionTitle from '../../components/simple/SectionTitle/SectionTitle'
import About from '../../components/composed/About/About'
import TimelineTimelineComponent from '../../components/composed/Timeline/TimelineComponent'
import ReccomendationsCard from '../../components/composed/ReccomendationsCard/ReccomendationsCard'
import ProjectsComponents from '../../components/composed/Projects/ProjectsComponents'
import { useNavigate } from 'react-router'

const Home = () => {
  const experienceData = [
    {
      date: '2023',
      title: 'Software Engineer at Nostra LLC',
      description: 'Enhanced user experience, addressed bugs, and added features, fostering collaboration, and improving app functionality. Designed visually appealing, intuitive components for increased engagement.'
    },
    {
      date: 'Nov 2020 - Nov 2022',
      title: 'Web Developer Consultant at Accenture Corporation (remote)',
      description: 'Revamped a Fortune 500 companys website with custom VueJS components, boosting conversions and satisfaction. Collaborated with large enterprise clients in an agile environment, enhancing web apps and content using Adobe Experience Manager. Resolved complex issues, elevating web functionality and user experience.'
    },
    {
      date: 'Jan 2019 - Nov 2020',
      title: 'Front-End Software Engineer at Capgemini (remote)',
      description: 'As a Front-end leader, co-created the first global Liferay Commerce CMS web-app for a telecom giant, excelling in time management, communication, and delegation. Enhanced websites with clean, documented code, meeting manager requirements. Collaborated with QA for top-notch project delivery.'
    },
    {
      date: 'Mar 2016 - May 2019',
      title: 'IT Project Coordinator at University of Costa Rica',
      description: 'Efficiently managed resources, workflows, training, and stakeholder communication. Fostered key relationships with government entities and suppliers. Achieved rapid project enrollment. Coordinated resource recruitment and adhered to branding guidelines for unified, impactful project promotion.'
    },
  ]

  const educationData = [
    {
      date: 'In course',
      title: 'M.P.M: Masters Degree in Project Management',
      description: 'Pursuing advanced skills in strategic project planning, leadership, execution, risk management, budgeting and cost control, and quality assurance.'
    },
    {
      date: '2015 - 2019',
      title: 'Computer Science and Multimedia Technology',
      description: 'Bachelor Degree recognized and validated by WES (World Education Services) from University of Costa Rica.'
    },
    {
      date: '2022 - 2024',
      title: 'Scrum Foundations Professional Certificate',
      description: 'Unlock potential in 2022 with the Scrum Foundations Professional Certificate by Certiprof. Dive deep into the world of Scrum methodologies and gain a competitive edge in your career with this industry-recognized certification.'
    },
    {
      date: '2021 - 2023',
      title: 'Project Management Specialization',
      description: 'A new career journey with the Project Management Specialization offered by Google Career Certificates on Coursera. I have successfully completed 4 out of the 6 enriching modules, equipping me with essential skills for a bright professional future.'
    },
    {
      date: '2022 - X',
      title: 'JS, Webpack and CSS overall courses',
      description: 'Discover the comprehensive courses from Frontend Master, providing in-depth insights into JavaScript, Webpack, and CSS. Elevated my skills and stay ahead in web development with these expertly crafted educational offerings.'
    }

  ]

  const navigate = useNavigate();

  return (
    <div>
      <Navigation></Navigation>
      {/**INTRODUCTION SECTION */}
      <div className='home'>
        <section id='intro-home' className='container'>
          <div className="home__welcome">
            <DecoOne className='homeDecorationOne' />
            <h1 className="home__name title-2"> Hello! I'm Erika Jones</h1>
            <p className='body-copy welcome__paragraph'>
              A versatile Software Engineer based in Ontario, Canada. I harness the power of front-end technologies like React JS and Vue JS to create compelling user experiences. <br /> 
              <br /><b>But my expertise extends far beyond coding:</b> I bring a rich mix of project management, problem-solving, and adaptability to every project. Passionate about continuous learning and growth, I'm always ready to tackle new challenges and contribute to diverse teams.<br />
              <br />
            </p>
            <div className='home__content-ctas'>
              <Button primary={true} size='medium' label='Get Started' onClick={() => navigate('/about')} />
              <div className='video-cta'>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <circle cx="24" cy="24" r="24" fill="#D2A188" />
                    <path d="M17.6001 28.872V19.1282C17.6001 16.7478 20.2393 15.3156 22.2351 16.6128L29.7304 21.4848C31.5504 22.6678 31.5504 25.3324 29.7304 26.5154L22.2351 31.3874C20.2393 32.6846 17.6001 31.2524 17.6001 28.872Z" fill="white" />
                  </svg>
                </div>
                <div>
                  <p className='bold'>Watch</p>
                  <p className='body-copy'>My Intro Video</p>
                </div>
              </div>
            </div>
            <DecoTwo className='homeDecorationTwo' />
          </div>
          <div className="home__image">
            <img
              src='/images/me3.png'
              alt=''
            />
          </div>
        </section>
      </div>

      {/**ABOUT SECTION */}
      <section id='about'>
        <About showSkills={true}></About>
      </section>

      {/**PROJECTS SECTION */}
      <section id='projects'>
        <ProjectsComponents showIntro={true}></ProjectsComponents>
      </section>

      {/**EXPERIENCE SECTION */}
      <section id='experience'>
        <TimelineTimelineComponent
          title='Experience'
          description={`I have had the pleasure to work with companies across a variety of industries. I'm always interested in new, exciting and challenging adventures.`}
          buttonLabel='Download CV'
          timelineData={experienceData}
          isDownload={true}
        ></TimelineTimelineComponent>
      </section>

      {/**REVIEWS SECTION */}
      <section id='reviews'>
        <div className='container'>
          <div className='reviews'>
            <div className='reviews-intro'>
              <SectionTitle title='Reviews' />
              <p className='body-paragraph'>As a dedicated team player, I've had the privilege of collaborating with talented individuals from various backgrounds. Their insights and feedback shed light on my commitment to fostering a collaborative and supportive environment in every project.</p>
              <div>
                <a className="a-button" href='https://www.linkedin.com/in/erikajns/details/recommendations/?detailScreenTabIndex=0' target="_blank">See All Feedback</a>

              </div>
            </div>
            <ReccomendationsCard
              name="Josue Somarribas"
              title="UX Designer & UX Engineer at First Factory"
              image="https://media.licdn.com/dms/image/D4E03AQFZDH2zl5RQTw/profile-displayphoto-shrink_800_800/0/1685276824375?e=1699488000&v=beta&t=Xxs-RFB228sxZdrR3O-RdMzq8YoS58TEbO2i7ZXgdnY"
              review="Erika is known for her strong soft skills, communication, tech expertise, and quick learning. A natural leader with a positive attitude and sound decision-making, she ensures success for any company or team."
            ></ReccomendationsCard>
            <ReccomendationsCard
              name="Pedro Matarrita"
              title="Visual Design Analyst at Accenture"
              image="https://media.licdn.com/dms/image/C4E03AQF6DB6I0Eo8sw/profile-displayphoto-shrink_800_800/0/1614371625196?e=1699488000&v=beta&t=SVPKIAOGtCh2rtLz7ra2naNgdrqfWmT29ZQ7G_uQktM"
              review="Erika, a go-to specialist in development, stands out as one of the most knowledgeable Front End developers I've worked with. Her leadership, insightful input, and pleasant demeanor ensure project success."
            ></ReccomendationsCard>
            <ReccomendationsCard
              name="Francisco Cruz"
              title="Web Developer at Accenture"
              image="https://media.licdn.com/dms/image/D4E03AQHJAtg2748iXQ/profile-displayphoto-shrink_800_800/0/1685136142152?e=1699488000&v=beta&t=fR6A39g-SWJKQymDdTDJO15Epg1wc18m7bAL8ZWXDJ8"
              review="Erika, highly regarded and well-liked, exceeded expectations.Trustworthy for any development or project management role, her responsibility and communication skills are admirable."
            ></ReccomendationsCard>
          </div>
        </div>
      </section>

      {/**EDUCATION SECTION */}
      <section id='education'>
        <TimelineTimelineComponent
          title='Education'
          description={`I have had the pleasure to work with companies across a variety of industries. I'm always interested in new, exciting and challenging adventures.`}
          buttonLabel='See Professional Certificates'
          timelineData={educationData}
          href='https://drive.google.com/drive/folders/1OvxS2ue6fDZoO5sDzycT5q1n-MW20w3H?usp=sharing'
        ></TimelineTimelineComponent>
      </section>
    </div>
  )
}

export default Home