import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import './Navigation.css'
import Logo from '../../simple/Logo/Logo'
import { Button } from '../../simple/Button/Button';
import TocRoundedIcon from '@mui/icons-material/TocRounded';
import { Drawer, IconButton, List, ListItem, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Resume from '../../../Resume.pdf'

const Navigation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [openDrawer, setOpenDrawer] = useState(false);
  //navbar scroll when active state
  const [navbar, setNavbar] = useState(false)

  //Bg change on scroll
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground)
  })

  return (
    <>{
      isMobile ? (
        <nav className={navbar ? "navbar active" : "navbar"}>
          <Logo className='logo'></Logo>
          <Drawer
            anchor='top'
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
          >
            <List>
              <ListItem className='mobileMenuItem' component={NavLink} to='/home' onClick={() => setOpenDrawer(false)}>
                Home
              </ListItem>
              <ListItem className='mobileMenuItem' component={NavLink} to='/about' onClick={() => setOpenDrawer(false)}>
                About Me
              </ListItem>
              <ListItem className='mobileMenuItem' component={NavLink} to='/projects' onClick={() => setOpenDrawer(false)}>
                Projects
              </ListItem>
              <ListItem className='mobileMenuItem' component={NavLink} to='/contact' onClick={() => setOpenDrawer(false)}>
                Contact
              </ListItem>
              <ListItem>
                <a href={Resume}
                  download="Erika-Jones_Resume"
                  target="_blank"
                  rel="noreferrer">
                  <Button primary={true} size='small' label='Download CV' />
                </a>
              </ListItem>
            </List>
          </Drawer>
          <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
            <TocRoundedIcon sx={{ fontSize: '2.3rem', marginRight: '2rem' }}></TocRoundedIcon>
          </IconButton>
        </nav>
      ) : (
        <nav className={navbar ? "navbar active" : "navbar"}>
          <Logo className='logo container-margin-left'></Logo>
          <div className='desktopMenu'>
            <Link to='/home' className='desktopMenuItem'>Home</Link>
            <Link to='/about' className='desktopMenuItem'>About Me</Link>
            <Link to='/projects' className='desktopMenuItem'>Projects</Link>
            <Link to='/contact' className='desktopMenuItem'>Contact</Link>
          </div>
          <a href={Resume}
                  download="Erika-Jones_Resume"
                  target="_blank"
                  rel="noreferrer">
          <Button primary={true} size='large' label='Download CV' style={{ marginRight: '3rem' }} />
          </a>
        </nav>
      )
    }

    </>

  )
}

export default Navigation