import React from 'react'
import './Header.css'
import PropTypes from 'prop-types';


const Header = ({label}) => {
    return (
        <div className='header container'>
            <p>{label}</p>
        </div>
    )
}

Header.propTypes = {
    /**
     * Header content
     */
    label: PropTypes.string.isRequired,
  };



export default Header