import React from 'react'
import Navigation from '../../components/composed/Navigation/Navigation'
import Header from '../../components/composed/Header/Header'
import './Projects.scss'
import ProjectsComponents from '../../components/composed/Projects/ProjectsComponents'

const Projects = () => {
  return (
    <div>
      <Navigation></Navigation>
      <div className='page-header'>
        <Header label="My Portfolio"></Header>
      </div>
      <ProjectsComponents showIntro={false}></ProjectsComponents>
    </div>
  )
}

export default Projects