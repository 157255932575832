import React from 'react'
import './ProjectsComponent.scss'
import ProjectCard from '../ProjectCard/ProjectCard'
import SectionTitle from '../../simple/SectionTitle/SectionTitle'

const ProjectsComponents = (props) => {
    const {showIntro} = props;
    const projectsData = [
        {
            image: 'portfolio.png',
            projectTitle: 'First Portfolio Website',
            projectType: 'Website/CV Landing/VueJS',
            linkTo: 'https://erikajns.github.io/portfolio/'
        },
        {
            image: 'motores.png',
            projectTitle: 'Motores Transitorios',
            projectType: 'Website/Wordpress/Client',
            linkTo: 'https://motorestransitorios.com/'
        },
        {
            image: 'weather.png',
            projectTitle: 'Weather App',
            projectType: 'Web-app/VueJS',
            linkTo: 'https://erikajns.github.io/weather-app/'
        },
        {
            image: 'todo.png',
            projectTitle: 'To Do App',
            projectType: 'Web-app/VueJS',
            linkTo: 'https://erikajns.github.io/todo-app/'
        }
    ]
    return (
        <div className='container'>
            <div className='projects-components'>
                {showIntro === true ? (<div className='projects-components__intro'>
                    <SectionTitle title='Projects' />
                    <p className='body-paragraph'>Here you will find some of the personal and clients projects that I created. Other projects can be shown at interview.</p>
                </div>):(null)}
                <div className='project-cards'>
                    {projectsData.map((project, index) => (
                        <ProjectCard
                            key={index}
                            image={project.image}
                            projectTitle={project.projectTitle}
                            projectType={project.projectType}
                            linkTo={project.linkTo}
                        />

                    ))}
                </div>
            </div>
        </div>
    )
}

export default ProjectsComponents