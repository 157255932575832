import React from 'react'
import Slider from "react-slick";

import './About.scss'
import SectionTitle from '../../simple/SectionTitle/SectionTitle'
import { Button } from '../../simple/Button/Button'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import { useNavigate } from 'react-router';

const About = (props) => {
  const { showSkills } = props;
  const navigate = useNavigate();
  //Slider config
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 5,
    speed: 4000,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 4000,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const skillsArray = [
    'HTML5', 'CSS', 'SASS', 'JavaScript', 'ES6', 'JQuery', 'VueJS', 'AngularJS', 'ReactJS', 'API', 'Firebase', 'JSON', 'BEM', 'AEM', 'Cross-Browser Dev.', 'NPM', 'AEM', 'CMS Liferay', 'Liferay Commerce', 'Crafter Software', 'WordPress',
    'PM Quality', 'PM Communications', 'PM Planning', 'Scheduling', 'JIRA', 'Microsoft Teams', 'Microsoft Planner', 'Confluence', 'One Drive', 'Outlook', 'Microsoft Word', 'Excel', 'Power Point', 'Microsoft Azure',
    'Adobe Photoshop', 'Adobe Illustrator', 'Wireframes', 'Mockups', 'Prototypes', 'UI', 'UX', 'Figma', 'Responsive',
  ]

  return (
    <div className='container'>
      <div className='about'>
        <div className="about__image">
        <img
              src='/images/me3.png'
              alt=''
            />
        </div>
        <div className='about__info'>
          <SectionTitle title='About Me' /> <br />
          <p className='body-copy about__paragraph'>
          My career, spanning over 5 years in diverse industries, showcases not just my proficiency in web development and project management, but also my innate ability to adapt and excel in any role. With a strong foundation in both technical and soft skills, I am driven to tackle challenges across various domains. My journey so far reflects a commitment to versatility, demonstrating that I'm not just limited to specific roles but am equipped to contribute significantly wherever my skills align.
          </p>
          <br />
          <p className='body-copy about__paragraph'>
          As a dynamic software engineer with over 5 years of experience across diverse industries, I bring a blend of technical prowess and strategic insight to every project. My journey has spanned the realms of Computer and Technology, Education, Automobile, and Telecommunications, where I honed my skills in web development, project management, and remote team collaboration. My approach is analytical and empathetic, ensuring that each project is managed with attention to detail and a deep understanding of its lifecycle. Currently expanding my horizons, I'm on a path to becoming a versatile Project Manager, eager to leverage my multicultural expertise and comprehensive knowledge to drive success in new ventures.
          </p>
          <div className='about-ctas'>
            <Button primary={true} size='medium' label='Hire Me'  onClick={() => navigate('/contact')} />
            <hr style={{ width: '4rem' }} />
            <p className='body-copy'>Follow me</p>
            <a
              className="body-copy body-copy-white"
              href="https://linkedin.com/in/erikajns"
              target="_blank"
            >
              <LinkedInIcon className='icon' sx={{ color: '#7C7C7C', cursor: 'pointer' }}></LinkedInIcon>
            </a>
            <a
              className="body-copy body-copy-white"
              href="https://github.com/erikajns?tab=repositories"
              target="_blank"
            >
              <GitHubIcon className='icon' sx={{ color: '#7C7C7C', cursor: 'pointer' }}></GitHubIcon>
            </a>
          </div>
        </div>
      </div>
      {
        showSkills === true ? (<div className='about__skills'>
          <Slider {...settings}>
            {skillsArray.map((skill, index) => (

              <div key={index}>
                <p className='body-paragraph skill'>{skill}</p>
              </div>
            ))}
          </Slider>
        </div>) : (null)
      }


    </div>
  )
}

export default About