import React from 'react'
import Navigation from '../../components/composed/Navigation/Navigation'
import About from '../../components/composed/About/About'
import Header from '../../components/composed/Header/Header'
import './AboutMe.css'

const AboutMe = () => {
  return (
    <div>
      <Navigation></Navigation>
      <div className='page-header'>
        <Header label="About me"></Header>
      </div>
      <About className='aboutComponent'></About>
    </div>
  )
}

export default AboutMe