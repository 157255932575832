import React from 'react'
import Navigation from '../../components/composed/Navigation/Navigation'
import Header from '../../components/composed/Header/Header'
import './Contact.scss'
import ContactMe from '../../components/composed/Contact/ContactMe'
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import RingVolumeRoundedIcon from '@mui/icons-material/RingVolumeRounded';

const Contact = () => {
  return (
    <div>
      <Navigation></Navigation>
      <div className='page-header'>
        <Header label="Contact"></Header>
      </div>
      <div className='container'>
        <div className="contact-form">
          <div className="contact-form__info">
            <h4 className=' bold bold-white'>GET IN TOUCH</h4>
            <h2>Let's Get In Touch!</h2>
            <div className="contact-form__info-contact">
              <div className='body-copy body-copy-white info'>
                <RingVolumeRoundedIcon></RingVolumeRoundedIcon>
                Phone: +1(905)325-2001
              </div>
              <div className='body-copy body-copy-white info'>
                <LocationOnRoundedIcon></LocationOnRoundedIcon>
                Current address: Niagara Falls, Canada.
              </div>
            </div>
            <hr />
            <div className="contact-form__info-rrss">
              <div>
                <a
                  className="body-copy body-copy-white"
                  href="https://linkedin.com/in/erikajns"
                  target="_blank"
                >
                  <LinkedInIcon></LinkedInIcon>
                  LinkedIn
                </a>
              </div>
              <div>
                <a
                  className="body-copy body-copy-white"
                  href="https://github.com/erikajns?tab=repositories"
                  target="_blank"
                >
                  <GitHubIcon></GitHubIcon>
                  Github
                </a>
              </div>
              <div>
                <a
                  className="body-copy body-copy-white"
                  href={'mailto:erika.jns@hotmail.com'}
                  target="_blank"
                >
                  <EmailRoundedIcon ></EmailRoundedIcon>
                  Email Job opportunities
                </a>
              </div>

            </div>
            <hr />
          </div>
          <ContactMe></ContactMe>
        </div>
      </div>
    </div>
  )
}

export default Contact