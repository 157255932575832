import React from 'react'
import './Logo.css'
import { useNavigate } from 'react-router'

const Logo = () => {
  const navigate = useNavigate();
  return (
    <div  onClick={() => navigate('/home')} className='primaryLogo'>Jones<span className='logoPoint'>.</span></div>
  )
}

export default Logo