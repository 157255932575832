/*
* @module components/composed/footer
*/

import React from 'react'
import './Footer.scss'
import { Button } from '../../simple/Button/Button'
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useNavigate } from 'react-router';

const Footer = () => {
const navigate = useNavigate();
   
    return (
        <div className='footer container'>
            <div className='footer__card-cta'>
                <div>
                    <h1 className='title-1 card-title'>Let's work together</h1>
                    <p className='body-copy card-description'>I would love to work with you. Let's have a talk about your big next project or your clients needs.</p>
                </div>
                <Button size='large' label="Let's talk" onClick={() => navigate('/contact')}/>
            </div>
            <nav className='footer__nav'>
                <div className='footerMenu'>
                    <a href='/home' className='footerMenuItem body-copy'>Home</a>
                    <a href='/about' className='footerMenuItem body-copy'>About</a>
                    <a href='/projects' className='footerMenuItem body-copy'>Projects</a>
                    <a href='/contact' className='footerMenuItem body-copy'>Contact</a>
                </div>
                <p className='body-copy'> Phone: +1(905)-325-2001 | Mail: erika.jns@hotmail.com</p>
            </nav>
            <hr />
            <div className='footer__copyrights'>
                <p className='body-copy'>Erika Jones</p>
                <p className='body-copy'>Copyright © 2023 Airwrecka Jones. All rights reserved</p>
                <div className='copyright__icons'>
                    <InstagramIcon className='icon' sx={{ color: '#7C7C7C', cursor: 'pointer' }} />
                    <a
                        className="body-copy body-copy-white"
                        href="https://github.com/erikajns?tab=repositories"
                        target="_blank"
                    >
                        <GitHubIcon className='icon' sx={{ color: '#7C7C7C', cursor: 'pointer' }} />
                    </a>
                    <a
                        className="body-copy body-copy-white"
                        href="https://linkedin.com/in/erikajns"
                        target="_blank"
                    ><LinkedInIcon className='icon' sx={{ color: '#7C7C7C', cursor: 'pointer' }} />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer