import React from 'react'
import './Timeline.scss'
import SectionTitle from '../../simple/SectionTitle/SectionTitle';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
  } from '@mui/lab/TimelineOppositeContent';
import { useNavigate } from 'react-router';
import Resume from '../../../Resume.pdf';

const TimelineComponent = (props) => {
    const { title } = props;
    const { description } = props;
    const { buttonLabel } = props;
    const { timelineData } = props;
    const { href } = props;
    const {isDownload} = props;
    const navigate = useNavigate();

    const onClickFunction = () => {
        navigate(`${href}`);
      }

    return (
        <div className='container'>
            <div className='timeline'>
                <div className='timeline__description'>
                    <SectionTitle title={title} />
                    <p className='body-paragraph'>{description}</p>
                    <div>
                        { isDownload ? (
                            <a href={Resume}
                            download="Erika-Jones_Resume"
                            target="_blank"
                            className='a-button'
                            rel="noreferrer">{buttonLabel}</a>
                        ):(<a className="a-button" href={href} target="_blank">{buttonLabel}</a>)

                        }
                    
                    </div>
                </div>
                <div className='timeline__info'>
                    {timelineData.length > 0 ? (
                        <Timeline sx={{
                            [`& .${timelineOppositeContentClasses.root}`]: {
                              flex: 0.2,
                            },
                          }}>
                            {timelineData.map((data, index) => (
                                <TimelineItem key={index}>
                                    <TimelineOppositeContent>
                                        <p className='bold'>{data.date}</p>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot sx={{ backgroundColor: '#D2A188' }} />
                                        <TimelineConnector sx={{ width: '0.05rem' }} />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <p className='bold'>{data.title}</p>
                                        <p className='body-paragraph'>{data.description}</p>
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                        </Timeline>
                    ) : (null)
                    }
                </div>
            </div>
        </div>
    )
}

export default TimelineComponent