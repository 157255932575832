import { Card, CardContent } from '@mui/material'
import React from 'react'
import './ReccomendationsCard.scss'
import { ReactComponent as QuoteImg } from '../../../assets/quote.svg'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const ReccomendationsCard = (props) => {
    const { review } = props;
    const { name } = props;
    const { title } = props;
    const { image } = props;

    return (
        <div className='reccomendation'>
            <Card>
                <CardContent>
                    <div className='reccomendation-card'>
                        <QuoteImg></QuoteImg>
                        <p className='body-paragraph'>{review}</p>
                        <div className='review-owner'>
                            <div className="reccomendation-card__image">
                                {
                                    image ? (
                                        <img src={image} alt='' />
                                    ) : (
                                        <AccountCircleIcon sx={{color: '#C8C8C8', fontSize: '3rem'}}></AccountCircleIcon>
                                    )
                                }

                            </div>
                            <div className='reccomendation-card__owner'>
                                <p className='bold'>{name}</p>
                                <p className='comments'>{title}</p>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}

export default ReccomendationsCard