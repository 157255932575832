import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Error404 from './pages/Error404';
import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import Projects from './pages/Projects/Projects';
import Footer from './components/composed/Footer/Footer';
import AboutMe from './pages/About/AboutMe';
import ScrollToTop from './components/simple/ScrollToTop';
import './general.scss';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop/>
      <Routes>
        <Route index element={<Home />}/>
        <Route path='/home' element={<Home />}/>
        <Route path='about' element={<AboutMe />}/>
        <Route path='/projects' element={<Projects />}/>
        <Route path='/contact' element={<Contact />}/>
        <Route path='*' element={<Error404 />}/>
      </Routes>
      <Footer></Footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
