import React from 'react'
import './SectionTitle.css'
import PropTypes from 'prop-types';
import { ReactComponent as TitleDeco } from '../../../assets/shapes.svg'

const SectionTitle = ({ title }) => {
    return (
        <div className='section-title'>
            <h1 className='title-1 title'>{title}</h1>
            <TitleDeco className='title-decoration' />
        </div>
    )
}

SectionTitle.propTypes = {
    title: PropTypes.string,
}

export default SectionTitle