import React from 'react'
import './ProjectCard.scss'
import {ReactComponent as LinkButton } from '../../../assets/linkButton.svg'

const ProjectCard = (props) => {
    const { image } = props;
    const { projectTitle } = props;
    const {projectType} = props;
    const {linkTo} = props;

    return (
        <div className='project-card'>
            <div className='project-card__image'>
            <img src={`/images/${image}`} />
            </div>
            <div className='project-card__info'>
                <div className='info-description'>
                    <p className='bold'>{projectTitle}</p>
                    <p className='mini-description'>{projectType}</p>
                </div>
                <div className='info-link'>
                <a href={linkTo} target="_blank">
                <LinkButton></LinkButton>
                </a>
                </div>
            </div>

        </div>
    )
}

export default ProjectCard