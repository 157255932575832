import './ContactMe.scss'
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const ContactMe = () => {
  const form = useRef();
  const [statusMessage, setStatusMessage] = useState("");


  /**
   * Function that connects to EmailJS and send the form data on submit
   */

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_sy2j1n6', 'template_bhpcs88', form.current, 'OvORN1Uiek_Zg3ZTJ')
      .then((result) => {
        console.log("SUCCESS!", result.text);
        form.current.reset();
        setStatusMessage("Thank you for your email");
      }, (error) => {
        setStatusMessage(`${error.text} happened`);
      });
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <input type="text" name="user_name" placeholder="Name" />
      <input type="email" name="user_email" placeholder="Email" />
      <textarea name="message" placeholder="Message"></textarea>
      <input type="submit" value="Send" className="submit-button" />
        <p className='body-copy success-message'>{statusMessage}</p>
    </form>
  );
};

export default ContactMe
